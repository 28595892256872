<template>
  <b-row>
    <b-col
      cols="12"
      xl="9"
      lg="8"
      md="7"
    >
      <b-card>
        <!-- form -->
        <b-form>
          <b-row>
            <!-- bio -->
            <b-col cols="12">
              <b-form-group
                label-for="structure"
                label="Structure"
              >
                <h4>{{ localOptions.nom }}</h4>
              </b-form-group>
            </b-col>
            <!--/ bio -->
          </b-row>
        </b-form>
        <div>
          <table>
            <tbody>
            <tr>
              <td class="pr-1">
                Adresse :
              </td>
              <td><span class="font-weight-bold">{{ localOptions.adresse }}</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="5"
      xl="3"
      lg="4"
    >
      <b-card
        no-body
        class="border-primary"
      >
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
          <h5 class="mb-0">
            Abonnement
          </h5>
          <b-badge variant="light-primary">
            Actif
          </b-badge>
        </b-card-header>

        <b-card-body>
          <div class="badge badge-light-primary rounded text-center w-100 p-1 mt-1 mb-2">
            <h5 class="text-primary mb-0 pb-0">Pack {{ localOptions.pack }}</h5>
          </div>
          <small class="text-muted w-100">Renouvellement le : {{ formatDateTime(localOptions.end_of_pack, { month: 'long', day: 'numeric', year: 'numeric'}) }}</small>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BRow, BCol, BCard, BBadge, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { formatDateTime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      formatDateTime,
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
