<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informations</span>
      </template>

      <b-overlay
        :show="!options.nom"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center d-flex align-items-center mt-5">
            <b-spinner
              small
              variant="primary"
              class="mr-1"
            />
            <span class="text-center">Chargement...</span>
          </div>
        </template>
        <parametres-informations
          v-if="options.nom"
          :information-data="options"
        />
      </b-overlay>
    </b-tab>
    <!--/ general tab -->

    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Application</span>
      </template>
      <b-overlay
        :show="!options.parametres"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center d-flex align-items-center mt-5">
            <b-spinner
              small
              variant="primary"
              class="mr-1"
            />
            <span class="text-center">Chargement...</span>
          </div>
        </template>
        <parametres-application
          v-if="options.parametres"
          :notification-data="options.parametres"
        />
      </b-overlay>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BSpinner, BOverlay,
} from 'bootstrap-vue'
import ParametresApplication from './ParametresApplication.vue'
import ParametresInformations from './ParametresInformations.vue'

export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    BOverlay,
    ParametresApplication,
    ParametresInformations,
  },

  data() {
    return {
      options: {},
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
    this.$store.commit('appConfig/UPDATE_SKIN', 'semi-dark')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  beforeCreate() {
    this.$http.get('/api/parametres/get').then(res => { this.options = res.data })
  },
}
</script>
