<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Tickets
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          v-model="localOptions.tickets_public"
          :checked="localOptions.tickets_public"
          name="check-button"
          switch
          inline
        >
          <span
            v-b-tooltip.hover
            title="Les utilisateurs peuvent voir les tickets ajoutés par n'importe quel autre utilisateur. Dans le cas contraire, l'utilisateur pourra seulement consulter ses tickets et ceux de son service (valable pour le pack 2 et 3). Les administrateurs peuvent accéder à tous les tickets."
          >Rendre les tickets publics <feather-icon icon="InfoIcon" /></span>
        </b-form-checkbox>
      </b-col>

      <h6 class="section-label mx-1 mb-2">
        Messages
      </h6>
      <b-col cols="12">
        <b-form-group
            label-for="messageGlobal"
            label="Message Avertissement"
        >
          <template #label><span
              v-b-tooltip.hover
              title="Ce message s'affichera au dessus de la liste d'incidents."
          >Message d'avertissement <feather-icon icon="InfoIcon" /></span></template>
          <b-form-textarea
              id="messageGlobal"
              v-model="localOptions.message_global"
              placeholder="Message à afficher au dessus de la liste des tickets"
          />
        </b-form-group>
      </b-col>

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">
        Maintenance
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch6"
          :checked="localOptions.desactivation_temporaire"
          name="check-button"
          disabled
          switch
          inline
        >
          <span
            v-b-tooltip.hover
            title="Vous pourrez dans la prochaine mise à jour, désactiver temporairement la création de ticket ainsi que la modification de ces derniers."
          >Désactiver temporairement l'ajout de tickets (bientôt disponible)</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="changeSettings(localOptions)"
        >
          valider
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BFormTextarea, BFormGroup, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    ToastificationContent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
    }
  },
  methods: {
    changeSettings(data) {
      this.$http.post('/api/parametres/modify', data).then(
        res => {
          console.log(res)
          if (res.status === 200 && res.data === 'ok') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modifications effectuées avec succès',
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue. Merci de re-essayer ultérieurement ou de contacter le support.',
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          }
        },
      )
    },
  },
}
</script>
